<template>
  <div class="youtube-links">
    <draggable
      v-model="val"
      class="youtube-links__list"
      @end="onEndDrag"
    >
      <a
        v-for="item in val"
        :key="item.id"
        class="youtube-links__link"
        :data-src="item.url"
        :data-poster="`https://img.youtube.com/vi/${item.videoId}/maxresdefault.jpg`"
      >
        <div
          class="youtube-links__remove"
          @click.stop="onClickRemove(item.id)"
        >
          <feather-icon icon="XIcon" />
        </div>
        <div class="youtube-links__maximize">
          <feather-icon
            size="24"
            icon="Maximize2Icon"
          />
        </div>
        <img
          class="youtube-links__img"
          :src="`https://img.youtube.com/vi/${item.videoId}/maxresdefault.jpg`"
          alt=""
        >
      </a>
      <div
        v-if="!isAddMode"
        slot="footer"
      >
        <b-button
          id="add-video"
          variant="outline-primary"
          size="sm"
          class="youtube-links__add"
          @click="onClickAdd"
        >
          {{ $t('Add Video') }}
        </b-button>
        <b-tooltip
          v-if="!isSubscriptionPremium"
          target="add-video"
          triggers="focus"
        >
          <div class="pt-50 pb-50">
            <span>{{ $t('cardYoutubeLinksTooltipNotPremium') }}</span>
            <b-button
              block
              size="sm"
              class="mt-50"
              variant="primary"
              @click="$router.push({ name: 'billing' })"
            >
              {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
            </b-button>
          </div>
        </b-tooltip>
      </div>
    </draggable>
    <div
      v-if="isAddMode"
      class="mt-1 position-relative"
    >
      <b-form-input
        ref="input"
        v-model="newValue"
        :disabled="isNewValueValidating"
        :state="!isNewValueValid ? false : null"
        placeholder="https://youtu.be/jfKfPfyJRdk"
        :class="['youtube-links__input', { 'youtube-links__input_with-error': !isNewValueValid }]"
        @blur="onBlurInput"
        @focus="onFocusInput"
        @keyup.esc.stop="onKeyupEsc"
        @keyup.enter.stop="$event.target.blur()"
      />
      <div :class="['youtube-links__error text-danger', { 'youtube-links__error_visible': !isNewValueValid }]">
        <small class="text-danger">{{ $t('validationErrorYoutubeLink') }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BTooltip,
  BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'

import lightGallery from 'lightgallery'
import lgVideo from 'lightgallery/plugins/video'

export default {
  name: 'YoutubeLinks',
  components: {
    BButton,
    BTooltip,
    BFormInput,
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      val: [],
      lightGalleries: [],
      isAddMode: false,

      newValue: '',
      isNewValueValid: true,
      isNewValueValidating: false,
    }
  },
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
  },
  watch: {
    value: {
      handler(newValue) {
        if (!this.val.length || newValue.toString() !== this.val.map(i => i.url).toString()) {
          this.val = newValue.map(i => ({ id: uuidv4(), url: i, videoId: this.getYoutubeIdByUrl(i) }))
          this.initGalleries()
        }
      },
      immediate: true,
    },
  },
  methods: {
    onKeyupEsc() {
      this.isAddMode = false
    },
    onClickAdd() {
      if (!this.isSubscriptionPremium) {
        return
      }

      this.newValue = ''
      this.isNewValueValid = true
      this.isNewValueValidating = false

      this.isAddMode = true

      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    onEndDrag() {
      this.$emit('input', this.val.map(i => i.url))
    },
    async initGalleries() {
      await this.$nextTick()

      if (this.lightGalleries.length) {
        this.lightGalleries.forEach(i => {
          i.refresh()
        })
        return
      }

      const els = document.getElementsByClassName('youtube-links__list')
      els.forEach(el => {
        this.lightGalleries = [
          ...this.lightGalleries,
          lightGallery(el, {
            download: false,
            licenseKey: process.env.VUE_APP_LIGHTGALLERY_LICENSEKEY,
            plugins: [lgVideo],
            preload: 'none',
            autoplayVideoOnSlide: true,
            selector: 'a',
          }),
        ]
      })
    },
    getYoutubeIdByUrl(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    onClickRemove(id) {
      this.$emit('input', this.val.filter(i => i.id !== id).map(i => i.url))
    },
    onFocusInput() {
      this.isNewValueValid = true
    },
    async onBlurInput() {
      if (!this.newValue) {
        this.isAddMode = false
        return
      }

      this.isNewValueValidating = true
      const youtubeId = this.getYoutubeIdByUrl(this.newValue)
      if (youtubeId) {
        const result = await fetch(`https://www.youtube.com/oembed?url=https://youtu.be/${youtubeId}&format=json`)
        if (!result.ok) {
          this.isNewValueValid = false
        }
      } else {
        this.isNewValueValid = false
      }
      this.isNewValueValidating = false

      if (this.isNewValueValid) {
        this.$emit('input', [...this.val.map(i => i.url), this.newValue])
        this.isAddMode = false
      }
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

@import '~lightgallery/css/lightgallery.css'
@import '~lightgallery/css/lg-video.css'
@import '@/assets/sass/lightgallery.sass'

.youtube-links
  &__list
    display: flex
    flex-wrap: wrap
    gap: 0.65rem
  &__link
    width: 177px
    height: 100px
    display: flex
    flex-shrink: 0
    border-radius: $border-radius
    position: relative
    &::before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: rgba($primary, 0.5)
      opacity: 0
      transition: opacity 0.25s ease-in-out
      border-radius: $border-radius
      cursor: move
    &:hover
      &::before,
      .youtube-links__remove,
      .youtube-links__maximize
        opacity: 1
  &__img
    display: block
    width: 100%
    border-radius: $border-radius
  &__remove
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 5px
    right: 5px
    background-color: $light
    width: 16px
    height: 16px
    border-radius: 4px
    cursor: pointer
    opacity: 0
    transition: opacity 0.25s ease-in-out
  &__maximize
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: flex
    opacity: 0
    transition: opacity 0.25s ease-in-out
    background-color: $light
    border-radius: 4px
    color: $dark
  &__add
    position: relative
    width: 177px
    height: 100px
    padding: 0
    transition: background-color 0.25s ease
  &__input
    transition-property: height, padding-bottom
    transition-duration: 0.25s
    transition-timing-function: ease-in-out
    &_with-error
      padding-bottom: calc(#{$input-padding-y} * 3)
      height: calc(#{$input-height} + (#{$input-padding-y} * 2))
  &__error
    position: absolute
    bottom: $label-margin-bottom
    left: $input-padding-x
    line-height: 1
    opacity: 0
    visibility: hidden
    transition-duration: 0.25s
    transition-property: visibility, opacity
    transition-timing-function: ease-in-out
    &_visible
      opacity: 1
      visibility: visible
</style>
