<template>
  <div class="images">
    <draggable
      ref="images-output"
      :list="value"
      class="images-output"
      :disabled="isPosting || isSavingOrder || deleting.length"
      @end="onEndDrag"
    >
      <a
        v-for="(item, itemIndex) in value"
        :key="item.id"
        :class="['images-output__image', { 'images-output__image_grayscale': getIsGrayscale(itemIndex) }]"
        :style="[{ backgroundImage: `url(${VUE_APP_IMG_BASE}/${item.linkName})` }]"
        :href="`${VUE_APP_IMG_BASE}/${item.linkName}`"
      >
        <img
          class="hidden"
          :src="`${VUE_APP_IMG_BASE}/${item.linkName}`"
          alt=""
        >
        <div
          v-if="isSavingOrder"
          class="images-output__saving-order"
        >
          <b-spinner
            variant="primary"
            small
          />
        </div>
        <b-spinner
          v-if="deleting.find(i => i === item.id)"
          class="images-output__deleting"
          variant="primary"
          small
        />
        <div
          v-if="!isPosting && !isSavingOrder && !deleting.length"
          class="images-output__remove"
          @click.stop.prevent="deleteImage(item)"
        >
          <feather-icon icon="XIcon" />
        </div>
        <div class="images-output__maximize">
          <feather-icon
            size="24"
            icon="Maximize2Icon"
          />
        </div>
      </a>
    </draggable>
    <b-button
      v-show="isMultiple || (!isMultiple && !value.length)"
      :id="String(_uid)"
      href="#"
      class="images-upload"
      @click="onUploadClick"
    >
      <span class="images-upload__content">
        <b-spinner
          v-if="isPosting"
          variant="primary"
          small
        />
        <span
          v-else
          class="images-upload__title"
        >
          {{ $t('Add Image') }}
        </span>
      </span>
    </b-button>
    <b-tooltip
      v-if="isSubscriptionInactiveOrBasic || isMedialLimitReached"
      :target="String(_uid)"
      triggers="focus"
    >
      <div class="pt-50 pb-50">
        <span>
          {{ isSubscriptionInactiveOrBasic ? $t('tooltipPhotosSubscribe') : $t('tooltipPhotosUpgrade') }}
        </span>
        <b-button
          block
          size="sm"
          class="mt-50"
          variant="primary"
          @click="$router.push({ name: 'billing' })"
        >
          {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
        </b-button>
      </div>
    </b-tooltip>
    <image-upload
      v-model="isImageUpload"
      :size="size"
      @posting="onPostingImage"
      @done="onDonePosting"
    />
  </div>
</template>

<script>
import {
  BTooltip,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import ImageUpload from '@/components/ImageUpload.vue'

import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'

export default {
  name: 'Images',
  components: {
    draggable,
    BTooltip,
    BButton,
    BSpinner,
    ImageUpload,
  },
  props: {
    size: {
      type: Object,
      required: true,
      default: () => ({ width: 800, height: 800 }),
    },
    isMultiple: {
      type: Boolean,
      required: true,
      default: true,
    },
    value: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    updateMutation: {
      type: Object,
      required: true,
      default: () => {},
    },
    updateVariables: {
      type: Function,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      deleting: [],
      isImageUpload: false,
      isPosting: false,
      isSavingOrder: false,
      lightGalleries: [],
    }
  },
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionActive: 'billing/isSubscriptionActive',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      VUE_APP_IMG_BASE: 'main/VUE_APP_IMG_BASE',
    }),
    isMedialLimitReached() {
      return this.subscription
        && this.isSubscriptionActive
        && this.value.length >= this.subscription.productDetails.restrictions.menuItemMediaAmount
    },
  },
  watch: {
    value() {
      this.initGalleries()
    },
  },
  methods: {
    async initGalleries() {
      await this.$nextTick()

      if (this.lightGalleries.length) {
        this.lightGalleries.forEach(i => {
          i.refresh()
        })
        return
      }

      const els = [this.$refs['images-output'].$el]
      els.forEach(el => {
        this.lightGalleries = [
          ...this.lightGalleries,
          lightGallery(el, {
            download: false,
            licenseKey: process.env.VUE_APP_LIGHTGALLERY_LICENSEKEY,
            plugins: [lgThumbnail],
            thumbnail: true,
          }),
        ]
      })
    },
    getIsGrayscale(index) {
      return this.subscription
        && this.isSubscriptionActive
        && (index + 1) > this.subscription.productDetails.restrictions.menuItemMediaAmount
    },
    onUploadClick() {
      if (
        this.isSubscriptionInactiveOrBasic
        || this.isMedialLimitReached
        || this.isPosting
        || this.deleting.length
        || this.isSavingOrder
      ) {
        return
      }
      this.isImageUpload = true
    },
    onPostingImage() {
      this.isPosting = true
    },
    async save(value) {
      await this.$apollo.mutate({
        mutation: this.updateMutation,
        variables: this.updateVariables(value),
      })
      this.$emit('input', value)
    },
    async onDonePosting(event) {
      await this.save([...this.value, event])
      this.isPosting = false
    },
    async deleteImage(item) {
      this.deleting = [...this.deleting, item.id]
      await this.save(this.value.filter(i => i.id !== item.id))
      this.deleting = this.deleting.filter(i => i !== item.id)
    },
    async onEndDrag(event) {
      if (event.oldIndex === event.newIndex) {
        return
      }
      this.isSavingOrder = true
      await this.save(this.value)
      this.isSavingOrder = false
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

@import '~lightgallery/css/lightgallery.css'
@import '~lightgallery/css/lg-thumbnail.css'
@import '@/assets/sass/lightgallery.sass'

.images
  margin-bottom: -0.65rem
  margin-right: -0.65rem

.images-output
  display: inline
  &__image
    display: inline-block
    vertical-align: top
    margin-bottom: 0.65rem
    margin-right: 0.65rem
    position: relative
    width: 100px
    height: 100px
    border-radius: $border-radius
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    &::before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: rgba($primary, 0.5)
      opacity: 0
      transition: opacity 0.25s ease-in-out
      border-radius: $border-radius
      cursor: move
    &_grayscale
      filter: grayscale(100%)
    &:hover
      &::before,
      .images-output__remove,
      .images-output__maximize
        opacity: 1
  &__remove
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 5px
    right: 5px
    background-color: $light
    width: 16px
    height: 16px
    border-radius: 4px
    cursor: pointer
    opacity: 0
    transition: opacity 0.25s ease-in-out
    color: $dark
  &__saving-order
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  &__deleting
    position: absolute
    top: 6px
    right: 6px
  &__maximize
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: flex
    opacity: 0
    transition: opacity 0.25s ease-in-out
    background-color: $light
    border-radius: 4px
    color: $dark

.images-upload
  display: inline-block
  vertical-align: top
  margin-bottom: 0.65rem
  margin-right: 0.65rem
  position: relative
  width: 100px
  height: 100px
  border-radius: $border-radius
  border: 1px solid $primary !important
  background-color: transparent !important
  padding: 0
  transition: background-color 0.25s ease
  &:hover
    box-shadow: none !important
    background-color: rgba($primary, 0.07) !important
  &:focus,
  &:active
    background-color: transparent !important
  &__content
    display: block
    position: absolute
    top: 50%
    left: 0
    right: 0
    transform: translateY(-50%)
    padding-left: 0.75rem
    padding-right: 0.75rem
    text-align: center
  &__title
    color: $primary
    font-weight: $font-weight-bold
    font-size: 0.857rem
    letter-spacing: 0.01rem
</style>
