import { mapGetters } from 'vuex'

import CREATE_BADGE from '@/gql/mutation/badges-tags/createBadge.gql'
import CREATE_BADGE_TRANSLATION from '@/gql/mutation/badges-tags/createBadgeTranslation.gql'
import DELETE_BADGE from '@/gql/mutation/badges-tags/deleteBadge.gql'
import DELETE_BADGE_TRANSLATION from '@/gql/mutation/badges-tags/deleteBadgeTranslation.gql'
import UPDATE_BADGE from '@/gql/mutation/badges-tags/updateBadge.gql'
import UPDATE_BADGE_TRANSLATION from '@/gql/mutation/badges-tags/updateBadgeTranslation.gql'

import CREATE_TAG from '@/gql/mutation/badges-tags/createTag.gql'
import CREATE_TAG_TRANSLATION from '@/gql/mutation/badges-tags/createTagTranslation.gql'
import DELETE_TAG from '@/gql/mutation/badges-tags/deleteTag.gql'
import DELETE_TAG_TRANSLATION from '@/gql/mutation/badges-tags/deleteTagTranslation.gql'
import UPDATE_TAG from '@/gql/mutation/badges-tags/updateTag.gql'
import UPDATE_TAG_TRANSLATION from '@/gql/mutation/badges-tags/updateTagTranslation.gql'

export default {
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
  },
  methods: {
    createBadge(name) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_BADGE,
          variables: {
            restaurant: this.restaurant.id,
            name,
          },
        }),
      ]
    },
    createBadgeTranslation(language, id, name) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_BADGE_TRANSLATION,
          variables: {
            restaurant: this.restaurant.id,
            language,
            originalId: id,
            name,
          },
        }),
      ]
    },
    deleteBadge(id) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_BADGE,
          variables: {
            id,
          },
        }),
      ]
    },
    deleteBadgeTranslation(language, id) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_BADGE_TRANSLATION,
          variables: {
            language,
            originalId: id,
          },
        }),
      ]
    },
    updateBadge(id, name) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_BADGE,
          variables: {
            id,
            name,
          },
        }),
      ]
    },
    updateBadgeTranslation(language, originalId, name) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_BADGE_TRANSLATION,
          variables: {
            restaurant: this.restaurant.id,
            language,
            originalId,
            name,
          },
        }),
      ]
    },

    createTag(name) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_TAG,
          variables: {
            restaurant: this.restaurant.id,
            name,
          },
        }),
      ]
    },
    createTagTranslation(language, id, name) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_TAG_TRANSLATION,
          variables: {
            restaurant: this.restaurant.id,
            language,
            originalId: id,
            name,
          },
        }),
      ]
    },
    deleteTag(id) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_TAG,
          variables: {
            id,
          },
        }),
      ]
    },
    deleteTagTranslation(language, id) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_TAG_TRANSLATION,
          variables: {
            language,
            originalId: id,
          },
        }),
      ]
    },
    updateTag(id, name) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_TAG,
          variables: {
            id,
            name,
          },
        }),
      ]
    },
    updateTagTranslation(language, originalId, name) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_TAG_TRANSLATION,
          variables: {
            restaurant: this.restaurant.id,
            language,
            originalId,
            name,
          },
        }),
      ]
    },
  },
}
