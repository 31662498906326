import {
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BCardTitle,
  BOverlay,
  BTooltip,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required,
  email,
  max,
} from '@validations'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters, mapActions } from 'vuex'
import Images from '@/components/Images.vue'
import psl from 'psl'
import moment from 'moment-timezone'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'
import IconTranslate from '@/components/icon/IconTranslate.vue'
import Translate from '@/components/Translate.vue'
import ImagesLimitation from '@/components/ImagesLimitation.vue'
import YoutubeLinks from '@/components/YoutubeLinks.vue'

import mixinGoogleTranslation from '@/mixins/google-translation'
import mixinRequests from '@/mixins/requests'
import mixinGqlBadgesTags from '@/mixins/gql/badges-tags'

import UPDATE_RESTAURANT from '@/gql/mutation/restaurant/updateRestaurant.gql'
import GET_RESTAURANTS_TRANSLATIONS from '@/gql/query/getRestaurantTranslations.gql'
import CREATE_RESTAURANT_TRANSLATION from '@/gql/mutation/restaurant/createRestaurantTranslation.gql'
import UPDATE_RESTAURANT_TRANSLATION from '@/gql/mutation/restaurant/updateRestaurantTranslation.gql'
import DELETE_RESTAURANT_TRANSLATION from '@/gql/mutation/restaurant/deleteRestaurantTranslation.gql'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadio,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BCardTitle,
    BOverlay,
    BTooltip,
    Images,
    ImagesLimitation,
    ModalConfirmDelete,
    IconTranslate,
    Translate,
    YoutubeLinks,

    // validations
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [
    mixinGoogleTranslation,
    mixinRequests,
    mixinGqlBadgesTags,
  ],
  data() {
    return {
      isFetching: true,

      name: '',
      description: '',
      fullAddress: '',
      phoneNumber: '',
      messenger: [],
      messengerOptions: ['WhatsApp', 'Telegram'],
      emailRestaurant: '',
      videos: [],
      logo: [],
      images: [],
      type: null,
      cuisine: [],
      primaryLanguage: null,
      translationLanguages: [],
      isModalConfirmDeleteTranslationLanguage: false,
      translationLanguageToRemove: null,
      socialLinks: [{ id: uuidv4(), target: '' }],
      measurementUnit: null,
      currency: null,
      isTranslateMode: false,
      restaurantMessageLimit: 500,
      showCurrencySymbol: true,
      showCurrencySymbolInFront: true,
      timezone: null,

      translations: [],
      translationActive: '',

      UPDATE_RESTAURANT,

      // validation rules
      required,
      email,
      max,
    }
  },
  computed: {
    ...mapGetters({
      restaurantTypes: 'dictionaries/restaurantTypes',
      restaurantCuisines: 'dictionaries/restaurantCuisines',
      currencies: 'dictionaries/currencies',
      measurementUnits: 'dictionaries/measurementUnits',
      languages: 'dictionaries/languages',
      timezones: 'dictionaries/timezones',

      restaurant: 'restaurant/restaurant',
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',

      isBadgesFetched: 'badgesTags/isBadgesFetched',
      badges: 'badgesTags/badges',
      badgesTranslations: 'badgesTags/badgesTranslations',
      isTagsFetched: 'badgesTags/isTagsFetched',
      tags: 'badgesTags/tags',
      tagsTranslations: 'badgesTags/tagsTranslations',
    }),
    measurementUnitsOptions() {
      return Object.entries(this.measurementUnits)
        .filter(i => Array.isArray(i[1]))
        .map(i => i[0])
    },
    optionsPrimaryLanguage() {
      return this.languages.filter(i => !this.translationLanguages.find(j => j.shortName === i.shortName))
    },
    optionsForTranslationLanguages() {
      return this.languages.filter(i => i.shortName !== (this.primaryLanguage ? this.primaryLanguage.shortName : null))
    },
    isAdditionalLanguagesDisabled() {
      return this.isSubscriptionInactive
        || (
          this.subscription
          && this.subscription.productDetails.restrictions.menuTranslationsAmount - 1
            <= this.translationLanguages.length
        )
    },
    linksPayload() {
      return [
        ...this.messenger.map(i => ({ target: i, type: 'phone' })),
        ...this.socialLinks
          .filter((i, iIndex) => iIndex + 1 !== this.socialLinks.length)
          .map(i => ({ target: i.target, type: 'social' })),
      ]
    },
    // 'socialLinksTarget' used in 'watch'
    socialLinksTarget() {
      return this.socialLinks.map(i => i.target)
    },
  },
  watch: {
    socialLinksTarget(newValue) {
      if (newValue.every(i => !!i)) {
        this.socialLinks = [...this.socialLinks, { id: uuidv4(), target: '' }]
      }
    },
    restaurant() {
      this.setData()
    },
    isBadgesFetched() {
      this.setData()
    },
    isTagsFetched() {
      this.setData()
    },
  },
  created() {
    this.setData()
  },
  mounted() {
    this.$nextTick(() => {
      const { hash } = this.$route
      if (hash) {
        this.scrollToElement(hash)
      }
    })
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
      getRestaurantTypes: 'dictionaries/getRestaurantTypes',
      getRestaurantCuisines: 'dictionaries/getRestaurantCuisines',

      setBadgesTranslations: 'badgesTags/setBadgesTranslations',
      setTagsTranslations: 'badgesTags/setTagsTranslations',
    }),
    async setData() {
      if (!this.restaurant || !this.isBadgesFetched || !this.isTagsFetched || !this.isFetching) {
        return
      }

      const responses = await Promise.all([
        this.$apollo.query({
          query: GET_RESTAURANTS_TRANSLATIONS,
          variables: {
            getRestaurantTranslationsId: this.restaurant.id,
          },
        }),
        this.getRestaurantTypes(),
        this.getRestaurantCuisines(),
      ])
      // eslint-disable-next-line no-undef
      this.translations = structuredClone(responses[0].data.getRestaurantTranslations)

      this.name = this.restaurant.name || ''
      this.fullAddress = this.restaurant.address1 || ''
      this.phoneNumber = this.restaurant.phoneNumber1 || ''
      this.emailRestaurant = this.restaurant.email || ''
      this.description = this.restaurant.description || ''
      this.videos = [...this.restaurant.videos]
      this.logo = this.restaurant.logo ? [this.restaurant.logo] : []
      this.images = [...this.restaurant.images]
      this.type = this.restaurant.restaurantTypes && this.restaurant.restaurantTypes.length
        ? this.restaurant.restaurantTypes[0]
        : null
      // eslint-disable-next-line no-undef
      this.cuisine = structuredClone(this.restaurant.restaurantCuisines)
      this.currency = this.currencies.find(i => i.code === this.restaurant.primaryCurrency) || null
      this.measurementUnit = this.restaurant.measurementUnits
      this.primaryLanguage = this.languages.find(i => i.shortName === this.restaurant.primaryLanguage)
      this.translationLanguages = this.restaurant.translationLanguages
        .map(i => this.languages.find(j => j.shortName === i))
      this.messenger = this.restaurant.links
        ? this.restaurant.links.filter(i => i.type === 'phone').map(i => i.target)
        : []
      const socialLinks = this.restaurant.links
        ? this.restaurant.links
          .filter(i => i.type === 'social')
          .map(i => ({ id: uuidv4(), target: i.target }))
        : []
      this.socialLinks = [...socialLinks, { id: uuidv4(), target: '' }]
      this.showCurrencySymbol = this.restaurant.showCurrencySymbol
      this.showCurrencySymbolInFront = this.restaurant.showCurrencySymbolInFront
      this.timezone = this.restaurant.timezone
        ? this.getFormattedTimezone(this.restaurant.timezone)
        : this.getFormattedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)

      if (!this.translationActive) {
        const translationActive = this.translations.find(i => i.isEnabled)
        this.translationActive = translationActive ? translationActive.language : ''
      }

      this.isFetching = false
    },
    getFormattedTimezone(inputValue) {
      const timezone = this.timezones.find(i => i.id === inputValue)
      if (timezone) {
        return timezone.id
      }

      const inputValueMoment = moment.tz(inputValue)
      const inputValueOffset = inputValueMoment.utcOffset()
      const timezoneSimilar = this.timezones.find(i => {
        const iMoment = moment.tz(i.id)
        const iOffset = iMoment.utcOffset()
        return iOffset === inputValueOffset
      })
      if (timezoneSimilar) {
        return timezoneSimilar.id
      }

      return null
    },
    getSocialLinkIcon(linkUrl) {
      let link = linkUrl
      if (link.indexOf('//') > -1) {
        // eslint-disable-next-line prefer-destructuring
        link = link.split('/')[2]
      } else {
        // eslint-disable-next-line prefer-destructuring
        link = link.split('/')[0]
      }

      const parsedLink = psl.get(link)
      if (parsedLink) {
        if (parsedLink === 'instagram.com') return 'InstagramIcon'
        if (parsedLink === 'facebook.com') return 'FacebookIcon'
        if (parsedLink === 'twitter.com') return 'TwitterIcon'
        if (parsedLink === 'youtube.com') return 'YoutubeIcon'
      }

      return 'GlobeIcon'
    },
    scrollToElement(selector) {
      const element = document.querySelector(selector)
      const navbar = document.querySelector('.header-navbar-shadow')

      window.scrollTo({
        top: element.getBoundingClientRect().top - navbar.offsetHeight,
        behavior: 'smooth',
      })
    },
    onAddLanguage(event) {
      this.setTranslateMode(false)
      window.scrollTo({ top: 0 })
      this.$nextTick(() => {
        this.scrollToElement(event)
      })
    },
    setTranslateMode(event) {
      this.isTranslateMode = event
    },

    onInputLogo(event) {
      this.logo = event
      this.updateRestaurant({ logo: this.logo.length ? this.logo[0] : null })
    },
    onInputImages(event) {
      this.images = event
      this.updateRestaurant({ images: this.images })
    },

    postTranslation(language) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_RESTAURANT_TRANSLATION,
          variables: {
            originalId: this.restaurant.id,
            language,
            name: null,
            description: null,
          },
        }),
      ]
    },
    deleteTranslation(language) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_RESTAURANT_TRANSLATION,
          variables: {
            originalId: this.restaurant.id,
            language,
          },
        }),
      ]
    },
    patchTranslationActive() {
      const translation = this.translations.find(i => i.language === this.translationActive)
      return [
        this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT_TRANSLATION,
          variables: {
            originalId: this.restaurant.id,
            language: translation.language,
            name: translation.name || null,
            description: translation.description || null,
          },
        }),
      ]
    },
    async onClickTranslateField(path) {
      await this.translateField(path)
      this.requests.push('patchTranslationActive')
    },
    async translateAll() {
      const initialRouteName = this.$route.name
      const initialTranslationActive = this.translationActive

      const response = await this.getGoogleTranslation(
        [this.name, this.description],
        this.primaryLanguage.shortName,
      )
      if (
        !response
        || initialRouteName !== this.$route.name
        || initialTranslationActive !== this.translationActive
      ) {
        return
      }

      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: response[0],
            description: response[1],
          }
        }
        return i
      })

      this.requests.push('patchTranslationActive')
    },
    onClearTranslation() {
      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: '',
            description: '',
          }
        }
        return i
      })
      this.requests.push('patchTranslationActive')
    },

    patchBase(name, value) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            [name]: value,
          },
        }),
      ]
    },

    patchName() {
      return this.patchBase('name', this.name)
    },
    onBlurName() {
      if (!this.name) {
        return
      }

      this.updateRestaurant({ name: this.name })
      this.requests.push('patchName')
    },

    patchAddress() {
      return this.patchBase('address1', this.fullAddress)
    },
    onBlurAddress() {
      this.updateRestaurant({ address1: this.fullAddress })
      this.requests.push('patchAddress')
    },

    patchEmail() {
      return this.patchBase('email', this.emailRestaurant)
    },
    onBlurEmail(isErrors) {
      if (isErrors) {
        return
      }

      this.updateRestaurant({ email: this.emailRestaurant })
      this.requests.push('patchEmail')
    },

    patchPhone() {
      return this.patchBase('phoneNumber1', this.phoneNumber)
    },
    onBlurPhone() {
      this.updateRestaurant({ phoneNumber1: this.phoneNumber })
      this.requests.push('patchPhone')
    },

    patchTimezone() {
      return this.patchBase('timezone', this.timezone)
    },
    onBlurTimezone() {
      this.updateRestaurant({ timezone: this.timezone })
      this.requests.push('patchTimezone')
    },

    patchDescription() {
      return this.patchBase('description', this.description)
    },
    onBlurDescription(isErrors) {
      if (isErrors) {
        return
      }

      this.updateRestaurant({ description: this.description })
      this.requests.push('patchDescription')
    },

    patchMeasurementUnit() {
      return this.patchBase('measurementUnits', this.measurementUnit)
    },
    onBlurMeasurementUnit() {
      this.updateRestaurant({ measurementUnits: this.measurementUnit })
      this.requests.push('patchMeasurementUnit')
    },

    patchCurrency() {
      return this.patchBase('primaryCurrency', this.currency ? this.currency.code : null)
    },
    onBlurCurrency() {
      this.updateRestaurant({ primaryCurrency: this.currency ? this.currency.code : null })
      this.requests.push('patchCurrency')
    },

    patchShowCurrencySymbol() {
      return this.patchBase('showCurrencySymbol', this.showCurrencySymbol)
    },
    onChangeShowCurrencySymbol() {
      this.updateRestaurant({ showCurrencySymbol: this.showCurrencySymbol })
      this.requests.push('patchShowCurrencySymbol')
    },

    patchShowCurrencySymbolInFront() {
      return this.patchBase('showCurrencySymbolInFront', this.showCurrencySymbolInFront)
    },
    onChangeShowCurrencySymbolInFront() {
      this.updateRestaurant({ showCurrencySymbolInFront: this.showCurrencySymbolInFront })
      this.requests.push('patchShowCurrencySymbolInFront')
    },

    patchLinks() {
      return this.patchBase('links', this.linksPayload)
    },
    saveLinks() {
      this.updateRestaurant({ links: this.linksPayload })
      this.requests.push('patchLinks')
    },
    onChangeMessenger() {
      this.saveLinks()
    },
    removeSocialLink(id) {
      this.socialLinks = this.socialLinks.filter(i => i.id !== id)
      this.saveLinks()
    },
    onBlurSocialLinks() {
      this.saveLinks()
    },

    patchVideos() {
      return this.patchBase('videos', this.videos)
    },
    onInputYoutubeLinks(event) {
      this.videos = [...event]
      this.updateRestaurant({ videos: [...this.videos] })
      this.requests.push('patchVideos')
    },

    patchType() {
      return this.patchBase('restaurantTypes', this.type ? [this.type.id] : [])
    },
    onBlurType() {
      this.updateRestaurant({ restaurantTypes: this.type ? [this.type] : [] })
      this.requests.push('patchType')
    },

    patchCuisines() {
      return this.patchBase('restaurantCuisines', this.cuisine.map(i => i.id))
    },
    updateCuisines() {
      this.updateRestaurant({ restaurantCuisines: this.cuisine })
      this.requests.push('patchCuisines')
    },
    onBlurCuisines() {
      this.updateCuisines()
    },
    onOptionDeselectedCuisines() {
      this.updateCuisines()
    },

    patchPrimaryLanguage() {
      return this.patchBase('primaryLanguage', this.primaryLanguage.shortName)
    },
    onBlurPrimaryLanguage() {
      this.updateRestaurant({ primaryLanguage: this.primaryLanguage.shortName })
      this.requests.push('patchPrimaryLanguage')
    },

    patchTranslationLanguages() {
      return this.patchBase('translationLanguages', this.translationLanguages.map(i => i.shortName))
    },
    requestsOnAddTranslationLanguage(language) {
      return [
        ...this.patchTranslationLanguages(),
        ...this.postTranslation(language),
      ]
    },
    onInputTranslationLanguages(newValue) {
      const toRemove = this.translationLanguages.find(i => !newValue.find(j => j.shortName === i.shortName))
      if (toRemove) {
        this.translationLanguageToRemove = toRemove
        this.isModalConfirmDeleteTranslationLanguage = true
      }

      const toAdd = newValue.find(i => !this.translationLanguages.find(j => j.shortName === i.shortName))
      if (toAdd) {
        this.translationLanguages = [...this.translationLanguages, toAdd]

        this.translations = [
          ...this.translations,
          {
            language: toAdd.shortName,
            isEnabled: true,
            name: '',
            description: '',
          },
        ]
        if (this.translations.length === 1) {
          this.translationActive = toAdd.shortName
        }

        this.updateRestaurant({ translationLanguages: this.translationLanguages.map(i => i.shortName) })
        const requestsOnAddTranslationLanguage = this.requestsOnAddTranslationLanguage.bind(this, toAdd.shortName)
        this.requests.push(requestsOnAddTranslationLanguage)

        this.createBadgesTagsTranslations(toAdd.shortName)
      }
    },
    requestsOnDeleteTranslationLanguage(language) {
      return [
        ...this.patchTranslationLanguages(),
        ...this.deleteTranslation(language),
      ]
    },
    onDeleteTranslationLanguage() {
      this.translationLanguages = this.translationLanguages
        .filter(i => i.shortName !== this.translationLanguageToRemove.shortName)

      this.translations = this.translations.filter(i => i.language !== this.translationLanguageToRemove.shortName)
      if (this.translationActive === this.translationLanguageToRemove.shortName) {
        const translationActive = this.translations.find(i => i.isEnabled)
        this.translationActive = translationActive ? translationActive.language : ''
      }

      this.updateRestaurant({ translationLanguages: this.translationLanguages.map(i => i.shortName) })
      const requestsOnAddTranslationLanguage = this.requestsOnDeleteTranslationLanguage.bind(
        this,
        this.translationLanguageToRemove.shortName,
      )
      this.requests.push(requestsOnAddTranslationLanguage)
      this.deleteBadgesTagsTranslations(this.translationLanguageToRemove.shortName)

      this.isModalConfirmDeleteTranslationLanguage = false
      this.translationLanguageToRemove = null
    },

    async createBadgesTagsTranslations(language) {
      const responses = await Promise.all([
        ...this.badges.map(i => this.createBadgeTranslation(language, i.id, '')[0]),
        ...this.tags.map(i => this.createTagTranslation(language, i.id, '')[0]),
      ])

      const badgesTranslations = responses
        .filter(i => i.data.createBadgeTranslation)
        .map(i => i.data.createBadgeTranslation)
      const tagsTranslations = responses
        .filter(i => i.data.createTagTranslation)
        .map(i => i.data.createTagTranslation)

      this.setBadgesTranslations([...this.badgesTranslations, ...badgesTranslations])
      this.setTagsTranslations([...this.tagsTranslations, ...tagsTranslations])
    },
    deleteBadgesTagsTranslations(language) {
      // no requests needed (backend handle this moment)

      this.setBadgesTranslations(this.badgesTranslations.filter(i => i.language !== language))
      this.setTagsTranslations(this.tagsTranslations.filter(i => i.language !== language))
    },
  },
}
