<template>
  <i18n
    path="infoImagesLimitation"
    tag="small"
  >
    <template v-slot:slot>
      <span class="text-primary font-weight-bolder">5Mb</span>
    </template>
    <template v-slot:slot2>
      <span class="text-primary font-weight-bolder">JPG</span>
    </template>
    <template v-slot:slot3>
      <span class="text-primary font-weight-bolder">PNG</span>
    </template>
  </i18n>
</template>

<script>
export default {
  name: 'ImagesLimitation',
}
</script>
